<template>
  <AppPage
    :title="`❤️ ${$vuetify.lang.t('$vuetify.cute_list')}`"
    container
    :loading="loading"
  >
    <div class="list" v-if="!loading && cuteList.length">
      <PetListView
        v-for="(pet, index) in cuteList"
        :key="index"
        hide-status
        cute-list
        arrow
        @pet-clicked="petClicked"
        :pet="pet"
      />
    </div>
    <div v-else class="text-center">
      <p class="subtitle-1 my-5">
        {{ $vuetify.lang.t("$vuetify.no_cute_list") }}
      </p>
    </div>
  </AppPage>
</template>

<script>
import PetListView from "@/components/pet/pet-list-view";

import { createNamespacedHelpers } from "vuex";
const popupModule = createNamespacedHelpers("popup");
const petModule = createNamespacedHelpers("pet");

export default {
  name: "cute-list",
  components: { PetListView },

  data() {
    return {};
  },
  computed: {
    ...petModule.mapState(["cuteList", "loading"]),
  },
  methods: {
    ...popupModule.mapMutations(["SET_POPUP"]),
    ...petModule.mapActions(["FETCH_USER_CUTE_LIST"]),
    petClicked(pet) {
      this.SET_POPUP({
        show: true,
        component: "pet-popup",
        modalOptions: {
          maxWidth: "90%",
        },
        componentProps: {
          pet,
        },
      });
    },
  },
  created() {
    this.FETCH_USER_CUTE_LIST();
  },
};
</script>

<style lang="scss" scoped>
$radius: 50px;
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &-item {
    padding: 0.5rem 1.5rem;
    color: rgba(24, 24, 24, 0.5);
    display: flex;
    align-items: center;
    border: 1px solid rgba(24, 24, 24, 0.5);
    border-radius: $radius;
    position: relative;
    transition: all 0.3s ease;

    &:not(:first-child) {
      left: -2rem;

      &:not(.active) {
        padding-left: 2.1rem;
      }
    }

    &:first-child {
      &:not(.active) {
        padding-right: 2.2rem;
      }
    }

    span.label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      margin-left: 5px;
      text-transform: capitalize;
    }

    &.active {
      background-color: #0dbff2;
      border-color: #0dbff2;
      color: #fff;
      z-index: 11;
    }
  }
}
</style>
